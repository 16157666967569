<template>
  <div class="wrapper">
    <menu-ver type="profesor" :content="courses" v-on:theme="selectTheme" />
    <div class="container m-3">
      <div class="table-header">
        <button
          class="btn button buttonCreate"
          data-bs-toggle="modal"
          data-bs-target="#addModal"
          @click="getActions(0)"
        >
          <i class="fas fa-plus-circle"></i> {{ $t("actions.create") }}
        </button>
      </div>
      <table class="table" v-if="materiales && materiales.length > 0">
        <thead>
          <tr>
            <th scope="col">{{ $t("maintenance.languages.name") }}</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in materiales" :key="item.mate_id">
            <td>{{ item.mate_nombre }}</td>
            <td>
              <button
                class="btn button buttonDelete ms-2"
                @click="getActions(2, item)"
                data-bs-toggle="modal"
                data-bs-target="#deleteModal"
              >
                <i class="fas fa-trash"></i>
              </button>
              <a
                class="btn button buttonEdit"
                target="_blank"
                :href="`${servidor}api/ver_archivo.asp?mate_id=${item.mate_id}`"
              >
                <i class="fas fa-download"></i>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <p v-else>{{ $t("errors.3") }}</p>
    </div>
  </div>

  <!-- Modal add -->
  <div
    class="modal fade"
    id="addModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("maintenance.materiales.addTitle") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="cleanForms()"
          ></button>
        </div>
        <div class="modal-body">
          <div class="px-4 py-3">
            <div class="mb-3">
              <label class="form-label">{{
                $t(`maintenance.languages.name`) + " *"
              }}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                id="FormName"
                v-model.trim="mate_nombre"
                :placeholder="$t(`maintenance.languages.name`) + '*'"
                maxlength="50"
              />
            </div>
            <div class="mb-3">
              <FilePond
                ref="pond"
                :label-idle="imgText"
                class="imgFile"
                max-files="1"
              />
            </div>
            <span
              v-if="formError"
              class="text-danger"
              style="float: right; font-size: 12px"
              >{{ $t("errors.fieldOb") }}</span
            >
            <span v-else style="float: right; font-size: 12px">{{
              $t("errors.comment")
            }}</span>
          </div>
        </div>
        <div class="modal-footer">
          <button
            id="btnSend"
            type="submit"
            class="btn-light btnCrud"
            @click="addMaterial"
          >
            {{ $t("actions.send") }}
          </button>
          <button
            id="btnClean"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
            @click="cleanForms()"
          >
            {{ $t("maintenance.actions.cancel") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal delete -->
  <div
    class="modal fade"
    id="deleteModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{
              $t("maintenance.materiales.deleteTitle", { name: `${mateId}` })
            }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          {{ $t("maintenance.materiales.deleteMessage") }}
        </div>
        <div class="modal-footer" style="justify-content: center">
          <button
            id="btnDelete"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
            data-bs-toggle="modal"
            data-bs-target="#responseModal"
            @click="deleteMaterial"
          >
            {{ $t("maintenance.actions.confirm") }}
          </button>
          <button
            id="btnClean"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
          >
            {{ $t("maintenance.actions.cancel") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal response -->
  <div
    class="modal fade"
    id="responseModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("errors.title") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="px-4 py-3" style="text-align: center">
            <div v-if="loading" class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            <div v-else>
              <div class="modal-body" v-if="result === '0'">
                {{ $t("errors.0") }}
              </div>
              <div class="modal-body" v-else-if="result === '1'">
                {{ $t("errors.1") }}
              </div>
              <div class="modal-body" v-else-if="result === '2'">
                {{ $t("errors.2") }}
              </div>
              <div class="modal-body" v-else-if="result === '3'">
                {{ $t("errors.3") }}
              </div>
              <div class="modal-body" v-else-if="action === 2">
                {{ $t("errors.6") }}
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer" v-if="!loading">
          <button
            v-if="result === '0' || action === 2"
            type="button"
            class="btn button btn-return-result"
            data-bs-dismiss="modal"
            @click="cleanForms()"
          >
            {{ $t("actions.close") }}
          </button>
          <button
            v-if="action === 0 && result !== '0'"
            class="btn button btn-return-result"
            data-bs-target="#addModal"
            data-bs-toggle="modal"
            data-bs-dismiss="modal"
          >
            {{ $t("actions.return") }}
          </button>
          <button
            v-if="action === 1 && result !== '0'"
            class="btn button btn-return-result"
            data-bs-target="#editModal"
            data-bs-toggle="modal"
            data-bs-dismiss="modal"
          >
            {{ $t("actions.return") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MenuVer from "../../components/MenuVer";
import axios from "axios";
import { mapMutations, mapState } from "vuex";
import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

const FilePond = vueFilePond(FilePondPluginImagePreview);

export default {
  components: {
    MenuVer,
    FilePond,
  },
  data() {
    return {
      materiales: [],
      material: {},
      mate_nombre: "",
      mate_id: "",
      mateId: "",
      result: "",
      action: 0,
      id: this.$route.params.id,
      formError: false,
      loading: false,
      imgText: this.$t("actions.imgText"),
      courses: [
        // { curso_id: 1, curso_titulo: "prueba" },
        // { curso_id: 2, curso_titulo: "prueba1" },
        // { curso_id: 3, curso_titulo: "prueba2" },
      ],
    };
  },
  created() {
    this.getmateriales();
    this.getcourses();
    this.changeBreadCrumb(21);
    this.bigDrop();
  },
  computed: {
    ...mapState(["servidor"]),
  },
  methods: {
    ...mapMutations(["changeBreadCrumb", "bigDrop"]),
    async getcourses() {
      try {
        const res = await axios.post(
          `${this.servidor}api/op_usu.asp?tp=profesor&ac=listado`
        );
        // console.log("miscursos", res);
        if (res.data) {
          this.courses = res.data.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getmateriales() {
      try {
        const lista = {
          mate_curso_cd: this.id,
        };
        const res = await axios.post(
          `${this.servidor}api/op.asp?tp=material&ac=listado`,
          lista
        );
        if (res.data) {
          this.materiales = res.data.data;
        }
        // console.log("materiales", res);
      } catch (error) {
        console.error(error);
      }
    },
    async addMaterial() {
      this.result = "";
      // console.log("INMATE");
      if (this.validateForm()) {
        try {
          // console.log("INMATE2");
          this.loading = true;
          const res = await axios.post(
            `${this.servidor}api/op.asp?tp=material&ac=grabar`,
            this.formatDatas()
          );
          // console.log("res", res);
          this.result = res.data.RESULT;
          $("#addModal").modal("hide");
          $("#responseModal").modal("show");
        } catch (error) {
          console.error(error);
          this.result = "1";
          $("#addModal").modal("hide");
          $("#responseModal").modal("show");
        }
        this.loading = false;
      }
    },
    async deleteMaterial() {
      try {
        const borrado = {
          mate_id: this.id,
        };
        this.loading = true;
        const res = await axios.post(
          `${this.servidor}api/op.asp?tp=material&ac=borrar`,
          borrado
        );
        this.result = res.data.RESULT;
        // console.log(res);
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },
    getId(event) {
      this.mateId = event[0];
    },
    validateForm() {
      if (this.mate_nombre === "") {
        this.formError = true;
        return false;
      }
      return true;
    },
    cleanForms() {
      this.mate_id = "";
      this.mate_nombre = "";
      this.loading = false;
      this.formError = false;
      if (this.$refs.pond && this.$refs.pond.getFile(0)) {
        this.$refs.pond.removeFile(0);
      }
      this.getmateriales();
    },
    getActions(act, item) {
      this.action = act;
      if (act === 0) {
        return;
      }
      if (act === 1 && item) {
        this.material = item;
        this.mate_id = item.mate_id;
        this.mate_nombre = item.mate_nombre;
        return;
      }
      if (item.mate_id) {
        this.id = item.mate_id;
      }
    },
    formatDatas() {
      let formData = new FormData();
      // console.log("INMATE", this.mate_nombre.toUpperCase(), this.id);
      formData.append("mate_nombre", this.mate_nombre.toUpperCase());
      formData.append("mate_curso_cd", this.id);
      if (this.$refs.pond.getFile(0)) {
        formData.append("mate_fichero", this.$refs.pond.getFile(0).file);
      } else {
        formData.append("mate_fichero", "");
      }
      return formData;
    },
    selectTheme() {
      // this.$router.push({
      //   path: `/adminsu/mmaterial/1/${id}`
      // });
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
}
.btn {
  float: right;
}
</style>